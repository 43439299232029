<template>
  <b-card>
    <form-component v-if="data" :data="data" @submit="onSubmit" />
  </b-card>
</template>

<script>
import FormComponent from '@/components/elements/guarantee-details/FormComponent';
import NotificationMixin from '@/mixins/NotificationMixin';

import { all as getAll, update } from '@/http/guarantee-details';

export default {
  mixins: [NotificationMixin],
  components: {
    FormComponent,
  },

  data() {
    return {
      data: null,
    };
  },

  async created() {
    const response = await this.fetchData();

    this.data = response.data;
  },

  methods: {
    async fetchData() {
      return await getAll();
    },
    async onSubmit(data) {
      try {
        await update(data);

        this.showSuccessNotification('Dane zapisane', 'Dane zostały zapisane.');
      } catch (e) {
        this.showErrorNotification(
          'Problem z zapisaniem danych',
          'Wystąpił błąd podczas zapisywania danych. Skontaktuj się ze swoim developerem.',
        );
      }
    },
  },
};
</script>
