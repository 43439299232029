<template>
  <validation-observer ref="validationObserver">
    <b-form @submit.prevent="validationForm" class="form-container">
      <b-col>
        <h4 class="mb-2">Konfiguracja</h4>
        <b-form-group
          label="Na grzejniki stalowe, zawory kulowe  PROV, wężyki stalowe oraz ceramikę sanitarną"
          label-for="v-steel-radiators">
          <validation-provider
            #default="{ errors }"
            name="Gwarancja na grzejniki stalowe, zawory kulowe..."
            rules="required|positive|alpha_num">
            <b-form-input
              v-model="formData.steel_radiators"
              type="number"
              step="1"
              min="0"
              id="v-steel-radiators"
              placeholder="Gwarancja na grzejniki stalowe, zawory kulowe..." />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group
          label="Na korpus baterii (pozostałe elementy baterii mają 2-letnią gwarancję)"
          label-for="v-battery-body">
          <validation-provider
            #default="{ errors }"
            name="Gwarancja na korpus baterii"
            rules="required|positive|alpha_num">
            <b-form-input
              v-model="formData.battery_body"
              type="number"
              id="v-battery-body"
              placeholder="Gwarancja na korpus baterii" />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group label="Na przyłącza i zawory gazowe, wodomierze" label-for="v-connections-and-valves">
          <validation-provider
            #default="{ errors }"
            name="Gwarancja na przyłącza i zawory"
            rules="required|positive|alpha_num">
            <b-form-input
              v-model="formData.connections_and_valves"
              type="number"
              id="v-connections-and-valves"
              placeholder="Gwarancja na przyłącza i zawory" />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group label="Na pozostałe produkty" label-for="v-rest">
          <validation-provider
            #default="{ errors }"
            name="Gwarancja na pozostałe produkty"
            rules="required|positive|alpha_num">
            <b-form-input
              v-model="formData.rest"
              type="number"
              id="v-rest"
              placeholder="Gwarancja na pozostałe produkty" />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>

      <b-col class="mt-5">
        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit" variant="primary"> Zapisz </b-button>
      </b-col>
    </b-form>
  </validation-observer>
</template>

<script>
import NotificationMixin from '@/mixins/NotificationMixin';

export default {
  mixins: [NotificationMixin],
  props: {
    data: {
      type: [Object, null],
      required: true,
    },
  },

  data() {
    const data = this.data;

    return {
      formData: data,
    };
  },

  methods: {
    onSubmit() {
      this.$emit('submit', this.formData);
    },
    validationForm() {
      this.$refs.validationObserver.validate().then((success) => {
        if (success) {
          return this.onSubmit();
        }
        this.showFormValidationNotification();
      });
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
