var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"validationObserver"},[_c('b-form',{staticClass:"form-container",on:{"submit":function($event){$event.preventDefault();return _vm.validationForm($event)}}},[_c('b-col',[_c('h4',{staticClass:"mb-2"},[_vm._v("Konfiguracja")]),_c('b-form-group',{attrs:{"label":"Na grzejniki stalowe, zawory kulowe  PROV, wężyki stalowe oraz ceramikę sanitarną","label-for":"v-steel-radiators"}},[_c('validation-provider',{attrs:{"name":"Gwarancja na grzejniki stalowe, zawory kulowe...","rules":"required|positive|alpha_num"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","step":"1","min":"0","id":"v-steel-radiators","placeholder":"Gwarancja na grzejniki stalowe, zawory kulowe..."},model:{value:(_vm.formData.steel_radiators),callback:function ($$v) {_vm.$set(_vm.formData, "steel_radiators", $$v)},expression:"formData.steel_radiators"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Na korpus baterii (pozostałe elementy baterii mają 2-letnią gwarancję)","label-for":"v-battery-body"}},[_c('validation-provider',{attrs:{"name":"Gwarancja na korpus baterii","rules":"required|positive|alpha_num"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","id":"v-battery-body","placeholder":"Gwarancja na korpus baterii"},model:{value:(_vm.formData.battery_body),callback:function ($$v) {_vm.$set(_vm.formData, "battery_body", $$v)},expression:"formData.battery_body"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Na przyłącza i zawory gazowe, wodomierze","label-for":"v-connections-and-valves"}},[_c('validation-provider',{attrs:{"name":"Gwarancja na przyłącza i zawory","rules":"required|positive|alpha_num"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","id":"v-connections-and-valves","placeholder":"Gwarancja na przyłącza i zawory"},model:{value:(_vm.formData.connections_and_valves),callback:function ($$v) {_vm.$set(_vm.formData, "connections_and_valves", $$v)},expression:"formData.connections_and_valves"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Na pozostałe produkty","label-for":"v-rest"}},[_c('validation-provider',{attrs:{"name":"Gwarancja na pozostałe produkty","rules":"required|positive|alpha_num"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","id":"v-rest","placeholder":"Gwarancja na pozostałe produkty"},model:{value:(_vm.formData.rest),callback:function ($$v) {_vm.$set(_vm.formData, "rest", $$v)},expression:"formData.rest"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mt-5"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"type":"submit","variant":"primary"}},[_vm._v(" Zapisz ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }